import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ["password1", "password2"];

    connect () {
        this.element.addEventListener('blur', this.onBlur, true);
    }

    disconnect () {
        this.element.removeEventListener('blur', this.onBlur());
    }

    onBlur = (event) => {
        if (event.target == this.password2Target) {
            if (this.password1Target.value !== this.password2Target.value) {
                this.password1Target.setCustomValidity("Hasła nie są jednakowe!");
            } else {
                this.password1Target.setCustomValidity("");
            }
        }
    }
}
